import { getSimpleSideProps } from "@bs/api-portal";
import { Contents } from "@bs/contents";
import { InferGetServerSidePropsType } from "next";

export default function Home({
  content,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return <Contents content={content} />;
}
export const getServerSideProps = getSimpleSideProps({ page: "/" });
